$(document).ready(function () {

  if ($('.hamburger')[0]) {
    var hamburger = $(".hamburger");
    var hamburgerIn = $(".hamburger-inner");
    var menu = $(".menu__mobile");
    var body = $(".page-body");
    var block = $(".page-body");

    hamburger.on("click", function (e) {
      if (hamburger.hasClass('is-active')) {
        block.css('width', '100%');
      } else {
        var inW = $(window).innerWidth();
        block.css('width', inW);
      }
      hamburger.toggleClass("is-active");
      menu.toggleClass("is-open");
      body.toggleClass("is-fixed");
    });
  }

});