/*
  ================================================
    Sticky footer
  ================================================
*/
// when the page load, activate the sticky footer
window.addEventListener("load", activateStickyFooter);

function activateStickyFooter() {
  // need to adjust the footer top when the page just loads
  // because the footer may not be sticky
  adjustStickyFooter();

  // whenever the window is resized, we need to re-adjust the
  // footer top to update its position
  window.addEventListener("resize", adjustStickyFooter);
}

function adjustStickyFooter() {
  var footer = document.querySelector("#footer");
  var footerHeight = footer.offsetHeight;
  var windowHeight = window.innerHeight;
  var html = document.documentElement;
  var pageHeight = html.offsetHeight;

  if ($(footer).hasClass('footer-absolute')) {
    pageFooterHeight = pageHeight + footerHeight;
  } else {
    pageFooterHeight = pageHeight;
  }

  if (windowHeight > pageFooterHeight) {
    footer.classList.add('footer-absolute');
  } else {
    footer.classList.remove('footer-absolute');
  }
}